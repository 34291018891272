.history {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fcfcfc;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 16px 12px;
  align-items: center;
  .historyIMG {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    span {
      color: var(--color-text, #09090b);
      margin-left: 7px;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  span {
    color: var(--color-icon-muted, #71717a);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.historyText {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #fcfcfc;
  width: 100%;
  padding: 0 12px 12px 12px;
  span {
    color: #84818a;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

@media screen and (max-width: 460px) {
  .history {
    .historyIMG {
      span {
        font-size: 14px;
      }
    }
    span {
      width: 140px;
      font-size: 14px;
    }
  }
}
