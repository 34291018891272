.categorieCard {
  height: fit-content;
  width: 300px;
  border: 1px solid #e4e4e7;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
  span {
    font-family: Inter;
    font-size: 16px;
    font-style: bold;
    font-weight: 600;
    line-height: 24px;
  }
  p {
    font-family: Inter;
    font-size: 12px;
    font-style: medium;
    font-weight: 500;
    line-height: 24px;
    color: #71717a;
  }
  hr {
    color: #e4e4e7;
  }
  .cardWorkflowArea {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .cardWorkflow {
      padding: 4px 8px;
      border: 1px solid #e4e4e7;
      background-color: #ffffff;
      border-radius: 6px;
      font-size: 12px;
      font-family: Inter;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

.categorieCard:hover {
  transition: 0.3s;
  border: 1px solid #2563eb;
}

.firstCategorieCard {
  display: block;
}
.secondCategorieCard {
  display: none;
}

@media screen and (max-width: 895px) {
  .secondCategorieCardHR{
    margin: 16px 0;
  }
  .firstCategorieCard {
    display: none;
  }
  .secondCategorieCard {
    display: block;
    width: 100%;
  }
  .categorieCard{
    width: 100%;
    cursor: auto;
  }
  .secondCategorieCardTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
