.loginPage {
  height: 100vh;
  display: flex;
  padding-right: 0px;
  align-items: center;
  background: #fafafa;

  .bgImg {
    width: 47%;
    height: 100vh;
    object-fit: cover;
  }
  .bgLines {
    position: absolute;
    top: 82px;
    left: 65px;
    width: 586px;
    height: 583px;
  }
  .loginFormTT {
    width: 53%;
    display: flex;
    justify-content: center;
    align-items: center;
    .loginForm {
      width: 539px;
      height: 530px;
      display: flex;
      padding: 40px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      border-radius: 24px;
      background: #fff;
      .loginFormBG {
        width: 175.714px;
        object-fit: contain;
        height: 100px;
      }
      .loginInputs {
        display: flex;
        width: 459px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        input:focus::placeholder {
          transition: 0.2s;
          color: transparent;
        }
        h2 {
          color: var(--color-text, #09090b);
          text-align: center;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px;
          margin-bottom: 32px;
        }
        p {
          color: var(--color-text, #09090b);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 10px;
        }

        .errormessage {
          color: red;
          font-size: 12px;
        }
        .loginBtn {
          width: 100%;
          button {
            width: 100%;
            cursor: pointer;
            margin-top: 22px;
            margin-bottom: 32px;
            display: flex;
            height: var(--size-size-button-lg, 44px);
            padding: var(--spacing-spacing-sm, 8px)
              var(--spacing-spacing-lg, 16px);
            justify-content: center;
            align-items: center;
            gap: var(--spacing-spacing-sm, 8px);
            align-self: stretch;
            border-radius: var(--radii-radius-button, 6px);
            background: var(--color-bg-primary, #18181b);
            box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
              0px 1px 2px -1px rgba(0, 0, 0, 0.1);
            color: var(--color-text-onColor, #fff);
            background: var(--color-bg-primary, #18181b);
            outline: none;
            /* text-base/font-medium */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
        }
        .loginFooter {
          display: flex;
          margin-top: 8px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          .loginCheckBox {
            display: flex;
            align-items: center;
            .checkbox {
              cursor: pointer;
              display: flex;
              align-items: center;
            }
            span {
              margin-left: 8px;
              color: #333;
              text-align: center;
              font-family: Montserrat;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          h3 {
            color: #333;
            text-align: center;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
          }
        }
      }
    }
  }
}

// ? Responsive version

@media screen and (max-width: 1390px) {
  .bgLines {
    left: 0px !important;
  }
  .loginForm {
    width: 439px !important;
    // height: 430px !important;
    .loginInputs {
      width: 359px !important;
    }
  }
}

@media screen and (max-width: 1220px) {
  .bgLines {
    width: 500px !important;
    height: 530px !important;
    top: 102px !important;
    left: 15px !important;
  }
}

@media screen and (max-width: 1100px) {
  .bgLines {
    width: 400px !important;
    height: 430px !important;
    top: 342px !important;
    left: 50px !important;
  }
}

@media screen and (max-width: 1000px) {
  .bgLines {
    top: 232px !important;
    left: 30px !important;
  }
  .loginForm {
    width: 389px !important;
    // height: 430px !important;
    .loginInputs {
      width: 309px !important;
    }
  }
}

@media screen and (max-width: 930px) {
  .bgLines {
    width: 350px !important;
    height: 380px !important;
    top: 352px !important;
    left: 30px !important;
  }
  .loginForm {
    width: 389px !important;
    // height: 430px !important;
    .loginInputs {
      width: 309px !important;
    }
  }
}

@media screen and (max-width: 800px) {
  .bgLines {
    width: 300px !important;
    height: 330px !important;
    top: 252px !important;
    left: 30px !important;
  }
  .loginForm {
    width: 289px !important;
    // height: 430px !important;
    .loginInputs {
      width: 209px !important;
      h2 {
        font-size: 18px !important;
        margin-bottom: 18px !important;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .bgLines {
    width: 250px !important;
    height: 280px !important;
    top: 252px !important;
    left: 30px !important;
  }
}

@media screen and (max-width: 590px) {
  .bgLines {
    width: 220px !important;
    height: 250px !important;
    top: 272px !important;
    left: 25px !important;
  }
}

@media screen and (max-width: 550px) {
  .bgLines {
    width: 200px !important;
    height: 230px !important;
    top: 282px !important;
    left: 25px !important;
  }
  .loginForm {
    width: 239px !important;
    // height: 430px !important;
    gap: 16px !important;
    .loginInputs {
      width: 159px !important;
      h2 {
        font-size: 18px !important;
        margin-bottom: 18px !important;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .bgLines {
    width: 180px !important;
    height: 210px !important;
    top: 182px !important;
    left: 25px !important;
  }
}

// ? Mobile version

@media screen and (max-width: 460px) {
  .loginPage {
    flex-direction: column;
    height: fit-content !important;
    .bgImg {
      width: 100%;
      height: 293px;
      object-fit: cover;
    }
    .bgLines {
      top: 43px !important;
      left: 30% !important;
      width: 188px !important;
      height: 195.32px !important;
    }
    .loginFormTT {
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      .loginForm {
        width: 100% !important;
        .loginFormBG {
          width: 100% !important;
          height: 54px !important;
        }
        .loginInputs {
          width: 100% !important;
          h2 {
            font-size: 24px !important;
            margin-top: 32px;
            margin-bottom: 32px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .loginPage {
    .bgLines {
      left: 25% !important;
    }
  }
}
