.reportsTT {
  padding: 24px 24px 37px 24px;
  background: #fafafa;
  margin-left: 288px;
  border-top: 2px solid #e4e4e7;
  border-left: 2px solid #e4e4e7;
  .reports {
    display: flex;
    width: 100%;
    height: auto;
    // padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #fff;
    .reportsTitle {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      h2 {
        color: #2e2c34;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .reportsTitleTT {
        display: flex;
        align-items: center;
        gap: 20px;
        h2 {
          color: #2e2c34;
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .reportsTitleR {
        display: flex;
        align-items: center;
        a {
          button {
            width: 105px;
          }
        }
      }
    }

    .calendarText {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    .customersParent {
      width: 100%;
      overflow: auto;
    }
    #customers {
      margin-bottom: 24px;
      color: #000;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 100%;
    }
    #customers tr {
      border-bottom: #e4e4e7 1px solid;
    }

    #customers td,
    #customers th {
      text-wrap: nowrap;
      padding: 12px 16px;
      font-family: Inter;
      line-height: 16px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
    }
    #customers hr {
      border-bottom: 1px solid #e7e7e7;
    }

    #customers th {
      text-wrap: nowrap;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      color: #000;
      border-collapse: unset;
    }
    .newTicketTextDate {
      margin-right: 20px;
    }
    .prevNext {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }
  }
}
.dateFilterSecond {
  display: none;
}
.filter {
  display: flex;
  padding: 32px;
  gap: 32px;
  .filterSelect {
    width: 190px !important;
  }
}

// ? Loading animation

.spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid white;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  background-color: #0056b3;
}

// ? Responsive

@media screen and (max-width: 1100px) {
  .filter {
    flex-direction: column;
    .filterSelect {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 1000px) {
  .reportsTitleR {
    button {
      margin-right: 0 !important;
    }
  }
  .dateFilterSecond {
    width: 100%;
    display: unset;
  }
  .dateFilterFirst {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .reportsTT {
    margin-left: unset !important;
  }
}

@media screen and (max-width: 450px) {
  .paginitionForDistance {
    display: none !important;
  }
}
