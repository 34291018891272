.ticket22 {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background: var(--color-bg-info-muted, #eff6ff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.ticketDateSpan {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
  color: var(--color-icon-muted, #71717a);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.ticketTitle {
  display: flex;
  justify-content: space-between;
  .ticketTitleL {
    display: flex;
    gap: 12px;
    align-items: center;
    h3 {
      border-radius: var(--radii-radius-sm, 4px);
      background: var(--color-icon-info, #2563eb);
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px -1px rgba(0, 0, 0, 0.1);
      display: flex;
      padding: var(--spacing-spacing-2-xs, 4px) var(--spacing-spacing-md, 12px);
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: var(--color-icon-onColor, #fafafa);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    span {
      color: #2e2c34;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
    }
  }
  .ticketTitleR {
    display: flex;
    align-items: center;
    gap: 8px;
    div {
      img {
        border-radius: 50%;
        width: 32px;
        height: 32px;
      }
    }
    div {
      h3 {
        color: var(--color-icon-primary, #18181b);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      h4 {
        color: var(--color-icon-primary, #18181b);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
}
.ticketContent {
  margin-top: 16px;
  .ticketContentTitle {
    display: flex;
    gap: 12px;
    justify-content: flex-start;
    // margin-bottom: 6px;

    span {
      // margin-right: 12px;
      color: var(--color-text, #09090b);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .cashierID {
    color: var(--color-text, #09090b);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .ticketContentComment {
    margin-top: 3px;
    display: flex;
    justify-content: flex-start;
    color: #84818a;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    // span {
    // display: block;
    // width: 1050px;
    // word-wrap: break-word;
    // text-align: start;
    // }
  }
  hr {
    margin: 16px 0 16px 0;
    width: 100%;
    background: #e7e7e7;
  }
  .ticketWhoseTT {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ticketWhose {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: var(--color-text, #09090b);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration-line: underline;
      }
      .icraciTT {
        background-color: unset !important;
        display: flex;
        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: unset;
          padding: 0;
          background-color: unset;
          h3 {
            color: var(--color-icon-primary, #18181b);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
          span {
            color: var(--color-icon-primary, #18181b);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
      div {
        color: var(--color-text, #09090b);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        border-radius: 30px;
        background: var(--color-bg-elevated, #f4f4f5);
        padding: 4px 8px 4px 5px;
        align-items: center;
        gap: 12px;
      }
    }
    h5 {
      cursor: pointer;
      color: var(--color-text, #09090b);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
.ticketInProgress {
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  background: var(--color-bg-subtle, #fafafa);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.ticketEnd {
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  background: var(--color-bg-strong, #e4e4e7);
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.SecondTicketFilter {
  margin-bottom: 24px;
}
.ticketFilter {
  gap: 12px !important;
  justify-content: unset !important;
}

.numberToWp {
  width: 24px;
  height: 24px;
  margin-left: 12px;
  border-radius: 4px;
  padding: 4px;
  background-color: #dcfce7;
  cursor: pointer;
}

.ticketdetailsHomeTT {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  padding: 203px 210px 202px 220px;
  .ticketDetailsHome {
    background-color: #fff;
    border-color: #fff;
    border-radius: 8px;
    z-index: 100;
    display: flex;
    width: 1087px;
    height: 620px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    align-self: stretch;
  }
}

.ticketImg {
  width: 32px;
  height: 32px;
}

.checkBoxTT {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  .checkBoxLabel {
    margin-left: 8px;
  }
}

.ticketsOverflow {
  overflow: auto;
  height: 300px;
}

.ticket {
  width: 100%;
  border-radius: 8px;
  // cursor: pointer;
  margin-bottom: 12px;
}

.seeDetailsForResponsive {
  display: none;
}

.dialogTriggerSeeDetails {
  display: none;
}

.phoneNumText {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media screen and (max-width: 570px) {
  .ticketTitle {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .numberToWp {
    text-align: center;
  }
  .ticketsContent {
    padding: 20px !important;
  }
  .ticketContentTitle {
    span {
      margin-right: unset !important;
    }
  }
  .dialogTriggerSeeDetails {
    width: 100%;
    padding-top: 16px;
    display: block;
  }
  .seeDetailsForResponsive {
    display: block;
    color: var(--color-text, #09090b);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration-line: underline;
  }
  .ticketTitle {
    .ticketTitleL {
      flex-wrap: wrap;
      h3 {
        margin-bottom: 8px;
      }
    }
  }
  .ticketContentTitle {
    flex-direction: column;
    gap: 4px;
  }
  .ticketWhoseTT {
    span {
      display: none;
    }
  }
}
