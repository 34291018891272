.notFoundTT {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
  .notFound {
    width: 650px;
    height: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px 20px;
    h2 {
      font-weight: 600;
      font-size: 38px;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 30px;
    }
    span {
      padding: 15px 20px;
      border: 2px solid black;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
