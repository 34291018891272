.dashboardTicketAreaTitle {
  display: flex;
  padding: 20px;
  .dashboardTicketAreaRight {
    margin-left: 10px;
    .dashboardTicketAreaRightTT {
      display: flex;
      p {
        color: #71717a;
        font-family: inter;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        span {
          color: #09090b;
        }
      }
    }
    .dashboardTicketAreaRightEnd {
      display: flex;
      gap: 10px;
      margin-top: 9px;
      p {
        color: #71717a;
        font-family: inter;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        span {
          color: #09090b;
        }
      }
    }
  }
}
