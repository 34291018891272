.column {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.column::-webkit-scrollbar {
  display: none;
}

.dragAndDropCard {
  padding: 20px;
  .dragAndDropCardTitle {
    div {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
      }
    }
    p {
      font-family: Inter;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      color: #71717a;
      margin: 4px 0 8px 0;
    }
    h2 {
      width: 100%;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 2px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #2563eb;
    }
  }
  .dragAndDropCardCustomerData {
    margin-top: 12px;
    div {
      display: flex;
      justify-content: space-between;
      span {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: #09090b;
      }
    }
    span {
      margin-top: 2px;
      font-family: Inter;
      font-size: 10px;
      font-weight: 300;
      line-height: 20px;
      color: #09090b;
    }
  }
  .dragAndDropCardHr {
    margin: 12px 0;
    background-color: #fafafa52;
  }
  .dragAndDropCardEnd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dragAndDropCardEndLeft {
      p {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: #18181b;
      }
      span {
        font-family: Inter;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        color: #71717a;
      }
    }
    .dragAndDropCardEndRight {
      width: fit-content;
      padding: 4px 8px 4px 5px;
      height: 24px;
      justify-content: center;
      background-color: white;
      border-radius: 30px;
      display: flex;
      align-items: center;
      gap: 8px;
      .stageCircle {
        width: 12px !important;
        height: 12px !important;
        border-radius: 50%;
      }
      h2 {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #09090b;
      }
    }
  }
}
