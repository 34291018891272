.dashboard {
  margin-left: 288px;
  background-color: #fafafa;
  padding: 24px;
  border-top: 2px solid #e4e4e7;
  border-left: 2px solid #e4e4e7;
  .dashboardContent {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    width: 100%;
    .dashboardNewTicketSection {
      display: none;
    }
    .dashboardLeft {
      width: 60%;
      .announcments {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      .dashboardTicketAreaTT {
        background: #fff;
        border: 1px;
        border-radius: 12px;
        border-color: #e4e4e7;
        width: 100%;
        .dashboardTicketArea {
          padding: 0 20px;
          border-top: 20px solid white;
          border-bottom: 20px solid white;
          border-radius: 12px;
          //   height: 50vh;
          height: 689px;
          overflow: auto;
        }
      }
    }
    .dashboardRight {
      display: flex;
      flex-direction: column;
      width: 37%;
      border: 1px;
      padding: 24px;
      border-radius: 12px;
      background-color: #fff;
      border-color: #e4e4e7;

      .newTicketInpBoxDash {
        .inputSearch {
          display: flex;
          align-items: center;
          input {
            width: 85%;
            // height: 50px;
            padding: 8px 12px;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            border: 1px solid #e7e7e7;
            outline: #e7e7e7;
          }
          input::placeholder {
            color: #757575;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.28px;
          }
          .dashboardSearch {
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            height: 44px;
            width: 44px;
            background-color: #f4f4f5;
            cursor: pointer;
          }
        }
      }
      .newTicketSelectDash {
        .categoriesOpener {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 15px;
          padding-left: 15px;
          cursor: pointer;
          border: 1px solid #e4e4e7;
          border-radius: 6px;
          width: 100%;
          height: 40px;
        }
        select {
          border-radius: 4px;
          border: 1px solid #e7e7e7;
          display: flex;
          width: 366px;
          height: 50px;
          padding: 8px 12px;
          align-items: center;
          outline: #e7e7e7;
          gap: 63px;
        }
      }

      .newTicketHR {
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .newTicketHR2 {
        margin-top: 18px;
        margin-bottom: 13px;
      }
      .distance {
        margin-top: 16px;
      }
      .newTicketTitleDashboard {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .newTicketR {
          display: flex;
          align-items: center;
          span {
            margin-left: 12px;
            color: var(--color-text, #09090b);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
          }
        }
        span {
          color: var(--color-icon-muted, #71717a);
          font-family: Inter;
          font-size: 14px;
          font-style: light;
          font-weight: 300;
          line-height: 20px;
        }
      }
    }
  }
}

// .categoriesAreaTT {
//   width: 1050px !important;
//   background-color: #fafafa !important;
//   border-radius: 16px !important;
//   padding: 16px !important;
//   align-items: center;
//   .categorieAreaTitle {
//     width: 942px;
//     height: 100px;
//     background-color: #fff;
//   }
//   .categorieDetails {
//     background-color: #fff;

//     .categoriesArea {
//       display: flex;
//       flex-wrap: wrap;
//       gap: 24px;
//       padding: 20px;
//     }
//   }
// }

.categorieContent {
  background-color: #fafafa !important;
  padding: 16px;
}

.categoriesArea {
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  gap: 24px;
  height: 780px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-top: 8px;
  height: 500px;
  overflow: auto;
  justify-content: center;
}

.categorieTitle {
  display: flex;
  align-items: center;
  gap: 32px;
  background-color: #ffffff;
  padding: 32px;
  border-radius: 12px;
  span {
    white-space: nowrap;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  .categorieTT {
    display: flex;
    gap: 12px;
    button {
      width: 181px !important;
    }
    input {
      width: 181px !important;
    }
    input::placeholder {
      color: #757575;
    }
  }
}

.dashboardSelectInPopup {
  max-width: 191px;
}
.categorieTT {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 760px) {
  .dashboard {
    margin-left: unset !important;
  }
  .dashboardContent {
    flex-direction: column;
    .dashboardNewTicketSection {
      width: 100%;
      display: flex !important;
      margin-bottom: 16px;
      border-radius: 10px;
      cursor: pointer;
      padding: 16px 32px 16px 32px;
      justify-content: space-between;
      background-color: #ffffff;
      span {
        font-family: Inter;
        line-height: 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
    }
    .dashboardLeft {
      width: 100% !important;
      margin-bottom: 18px;
    }
    .dashboardRight {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 980px) {
  .categoriesArea {
    height: 350px;
  }
  .categorieTitle {
    flex-direction: column;
    align-items: unset !important;
    .categorieTT {
      flex-direction: column;
      button {
        width: 100% !important;
      }
      input {
        width: 100% !important;
      }
    }
  }
}
