.tabs {
  display: flex;
  flex-direction: column;
}

.tab-buttons {
  display: flex;
  height: 38px;
  padding: 4px 10px;
  align-items: center;
}
.tabContent {
  display: flex;
  text-align: center;
  align-items: center;
  margin-right: 44px;
  gap: 10px;
  p {
    color: #84818a;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
  }
}
.tab-buttons:last-child {
  margin: 100px;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  transition: border-bottom;
}

.tab-button.active {
  border-bottom: 2px solid #007bff;
}
