.info,
.warning,
.danger {
  display: flex;
  border-radius: 8px;
  padding: 10px 20px;
  .announcmentR {
    width: 99%;
    padding-left: 12px;
    p {
      font-family: inter;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    span {
      font-family: inter;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #71717a;
    }
  }
}
.info {
  background-color: #eff6ff;
}
.warning {
  background-color: #fefce8;
}
.danger {
  background-color: #fef2f2;
  .announcmentR {
    p {
      color: #dc2626;
    }
    span {
      color: #dc2626;
    }
  }
}
.announcmentIcon{
  // width: 50px;
  // margin-right: 15px;
}