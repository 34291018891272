.sideBar {
  position: absolute;
  // top: 170px;
  width: 288px;
  height: 905px;
  background: #fff;
  // border-right: 1px solid #E4E4E7;
  .sideBarLogo {
    width: 170px;
    height: 84px;
    display: flex;
    justify-content: center;
    padding: 7px 32px;
    flex-direction: column;
    align-items: flex-start;
    .sideBarLogoImg {
      // background-image: url(../bg-images/sideBarLogo.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 123px;
      margin-top: 7px;
      height: 70px;
    }
  }
  .sideBarUl {
    display: flex;
    flex-direction: column;
    a:hover {
      background: #e4e4e7 !important;
      transition: 0.5s;
    }
    a {
      li {
        padding: 16px 0px 16px 32px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: var(--color-text, #09090b);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        position: relative;
        width: 100%;
        padding-left: 10%;
        .groupTicketsCount {
          position: absolute;
          right: 0;
          padding: 4px 6px 4px 6px;
          background-color: #fef08a;
          border-radius: 100px;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
        }
      }
      cursor: pointer;
      gap: 12px;
      display: flex;
      align-items: center;
      color: #2e2c34;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      span {
        margin-right: 12px;
      }
    }
  }
}

.dashboardSideBar,
.myticketVisit,
.myGroupsVisit,
.allTicketsVisit,
.reportsVisit,
.reportsVisit2,
.reportsVisit3,
.faqVisit,
.createdByMeSidebar,
.settingsVisit,
.workflowReport,
.userWorkflowReport,
.visitDragAndDrop,
.licenseSidebar {
  background: var(--color-bg-elevated, #f4f4f5) !important;
}

.subCategoryNames {
  font-size: 14px !important;
  width: 170px;
  border-right: #f4f4f5 1px solid;
}

.sideBarReportParentClass {
  position: relative;
}
.sideBarReportArrowClass {
  position: absolute;
  right: 16px;
}

.responsiveMenuBar {
  position: absolute;
  left: 32px;
  top: 29px;
  cursor: pointer;
  display: none;
}

.sideBarUlResponsiveMenuBar {
  display: flex;
  height: 90vh;
  padding-bottom: 40px;
  overflow: auto;
  flex-direction: column;
  a:hover {
    background: #e4e4e7 !important;
    transition: 0.5s;
  }
  a {
    li {
      padding: 16px 0px 16px 32px;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: var(--color-text, #09090b);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      .groupTicketsCount {
        padding: 4px 6px 4px 6px;
        background-color: #fef08a;
        border-radius: 100px;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        margin-left: 10px;
      }
    }
    cursor: pointer;
    gap: 12px;
    display: flex;
    align-items: center;
    color: #2e2c34;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    span {
      margin-right: 12px;
    }
  }
}

@media screen and (max-width: 760px) {
  .menuBarIMG {
    width: 123px;
    margin-top: 3px;
    margin-bottom: 21px;
    height: 48px;
    object-fit: contain;
    padding-left: 32px;
  }
  .sideBar {
    width: 0 !important;
  }
  .sideBarUl {
    display: none !important;
  }
  .responsiveMenuBar {
    display: unset;
    z-index: 10;
  }
}
