.detailsContentRWhoseDate {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fcfcfc;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 16px 12px;
  align-items: center;
  .detailsContentRWhose {
    display: flex;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    span {
      color: var(--color-text, #09090b);
      margin-left: 7px;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  span {
    color: var(--color-icon-muted, #71717a);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.detailsContentRComment {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #fcfcfc;
  width: 100%;
  padding: 0 12px 12px 12px;
  span {
    color: #84818a;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .relative {
    .smilesForComment {
      position: absolute;
      width: fit-content;
      box-shadow: 0px 2px 4px -2px #1018281a;
      box-shadow: 0px 4px 6px -1px #0000001a;
      height: 40px;
      border-radius: 6px;
      padding: 6px 12px;
      display: flex;
      gap: 10px;
      background-color: #fafafa;
      // position: relative;
      .smileTriangleForComment {
        width: 12px;
        height: 12px;
        background-color: #fafafa;

        rotate: 135deg;
        position: absolute;
        top: -4px;
        left: 15px;
      }
      .smileItemParentForComment {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: unset !important;
        gap: 10px;
        .smileItem {
          border-radius: 50px;
          border: 1px solid #e4e4e7;
          padding: 4px 6px 4px 6px;
          cursor: pointer;
        }
      }
    }
  }
}

.editCommentButton {
  padding: 6px;
  background-color: #f4f4f5;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.editCommentButton:hover {
  transition: 0.3s;
  background-color: #e4e4e7;
}

.ticketCommentTextArea {
  margin-top: 6px;
  width: 100%;
  overflow: auto;
  height: 154px;
  resize: none;
  border-bottom: 45px solid white !important;
}

.editCommentSendButton {
  position: absolute;
  bottom: 6px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: #f4f4f5;
  border-radius: 6px;
}

.editCommentSendButton:hover {
  transition: 0.3s;
  background-color: #e4e4e7;
}

@media screen and (max-width: 450px) {
  .detailsContentRWhoseDate {
    padding: 12px 12px 16px 12px;
    .detailsContentRWhose {
      span {
        font-size: 14px;
      }
    }
    span {
      width: 140px;
      font-size: 14px;
    }
  }
}
