.alert {
  margin-top: 150px !important;
  // margin-left: 383px !important;
  // display: flex !important;
  padding: 36px 40px !important;
  // flex-direction: column !important;
  // justify-content: center !important;
  // align-items: center !important;
  gap: 24px !important;
  margin-left: 24% !important;
  width: 465px !important;
  // height: 244px !important;
}

.alertCloseButton {
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 4px;
  border-radius: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alertCloseButton:hover {
  transition: 0.3s;
  background-color: #e4e4e7 !important;
}

.alertDIV {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    color: var(--color-text, #09090b);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;
    margin-top: 24px;
  }
  p {
    color: var(--color-text-muted, #71717a);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

@media screen and (max-width: 800px) {
  .alert {
    margin-left: 10% !important;
  }
}

@media screen and (max-width: 660px) {
  .alert {
    margin-left: 3% !important;
  }
}

@media screen and (max-width: 580px) {
  .alert {
    margin-left: 5% !important;
    width: 400px !important;
  }
}

@media screen and (max-width: 500px) {
  .alert {
    margin-left: 5% !important;
    width: 350px !important;
  }
}

@media screen and (max-width: 440px) {
  .alert {
    margin-left: 2% !important;
    width: 320px !important;
  }
}

@media screen and (max-width: 391px) {
  .alert {
    margin-left: 2% !important;
    width: 300px !important;
  }
}

@media screen and (max-width: 385px) {
  .alert {
    margin-left: 1% !important;
    width: 290px !important;
  }
}
@media screen and (max-width: 370px) {
  .alert {
    margin-left: 0% !important;
    width: 290px !important;
  }
}

@media screen and (max-width: 363px) {
  .alert {
    margin-left: 2% !important;
    width: 270px !important;
  }
}
@media screen and (max-width: 347px) {
  .alert {
    margin-left: 0% !important;
    width: 270px !important;
  }
}
