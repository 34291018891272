.phoneNumInp {
  // select {
  //   outline: none;
  //   width: 80px;
  //   border-top-left-radius: 4px;
  //   border-bottom-left-radius: 4px;
  //   border: 1px solid #e7e7e7;
  //   padding: 8px 0 8px 12px;
  //   border-right: none;
  // }
  div {
    display: flex;
    .inputSearch {
      width: 100%;
      display: flex;
      align-items: center;
      .dashboardSearch {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        height: 44px;
        width: 44px;
        background-color: #f4f4f5;
        cursor: pointer;
      }
      input {
        width: 85%;
        // height: 50px;
        padding: 8px 12px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #e7e7e7;
        outline: #e7e7e7;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
