.ticketDetails {
  .detailsTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e4e4e7;
    .detailsTitleL {
      width: 1042px;
      display: flex;
      align-items: center;
      gap: 16px;
      // position: relative;
      div {
        position: relative;
      }
      h4 {
        color: var(--color-icon-muted, #71717a);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
      div {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-right: 8px;
        padding-left: 8px;
        div {
          cursor: pointer;
        }
        h3 {
          border-radius: var(--radii-radius-sm, 4px);
          background: var(--color-icon-info, #2563eb);

          /* Shadows/sm-strong */
          box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
            0px 1px 2px -1px rgba(0, 0, 0, 0.1);
          display: flex;
          padding: var(--spacing-spacing-2-xs, 4px)
            var(--spacing-spacing-md, 12px);
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: var(--color-icon-onColor, #fafafa);

          /* text-sm/font-medium */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
      span {
        color: var(--color-text, #09090b);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .detailsStatus {
        display: flex;
        position: relative;
        cursor: pointer;
        padding: 4px 5px;
        height: 32px;
        align-items: center;
        gap: 12px;
        border-radius: 30px;
        background: rgba(59, 138, 255, 0.2);
        .taskStatusDrop {
          position: absolute;
          top: 40px;
        }
      }
    }
    .ticketTTT {
      display: flex;
      align-items: center;
      gap: 12px;
      .ticketTTTimg {
        width: 32px;
        height: 32px;
        img {
          border-radius: 50%;
        }
      }
      .ticketTTTwords {
        padding-right: 26px;
        h5 {
          text-wrap: nowrap;
          color: var(--color-icon-primary, #18181b);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
        h6 {
          color: var(--color-icon-primary, #18181b);

          /* text-xs/font-regular */
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
    }
    .dialogCloseButton {
      min-width: 36px;
      min-height: 36px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f4f4f5;
    }
    .dialogCloseButton:hover {
      transition: 0.3s;
      background-color: #e4e4e7;
    }
    .detailsTitleR {
      width: 212px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      align-self: stretch;
      span {
        color: #84818a;
        text-align: right;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }
  .detailsContent {
    display: flex;
    .detailsContentL {
      position: relative;
      width: 60%;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      .contentCommentArea {
        display: flex;
        max-height: 240px;
        overflow-y: auto;
        padding: 12px;
        border-radius: 4px;
        background: #f8f8f8;
        margin-top: 16px;
        span {
          width: 100%;
          display: block;
          word-wrap: break-word;
          color: #616161;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
      .customFieldsParent {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .customFileds {
          display: flex;
          text-align: center;
          color: var(--color-text, #09090b);
          font-family: Inter;
          font-size: 14px;
          // padding: 10px 0;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .descriptionEditTextareaParent {
        margin-top: 16px;
        padding: 8px;
        background-color: #fafafa;
        .descriptionEditTextarea {
          height: 132px;
          resize: none;
        }
      }

      hr {
        margin-top: 16px;
        width: 622px;
        height: 1px;
        background: rgba(0, 0, 0, 0.15);
      }
    }
    .detailsContentHR {
      background: #e7e7e7;
      width: 1px;
      margin: 0 16px 0 16px;
      // height: 457px;
      height: 430px;
    }
    .detailsContentR {
      position: relative;
      display: flex;
      width: 40%;
      flex-direction: column;
      align-self: stretch;
      .detailsContentRTitle {
        margin-bottom: 16px;
        display: flex;
        gap: 24px;
        div {
          width: 1px;
          height: 24px;
          background-color: #e4e4e7;
        }
        .nonactive {
          color: var(--color-icon-muted, #71717a);
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          cursor: pointer;
        }
        .nonactive:hover {
          transition: 0.3s;
          color: #232324;
        }
        .active {
          cursor: pointer;
          color: var(--color-text, #09090b);
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
      .taskHistory {
        // display: flex;
        width: 100%;
        // padding: 12px;
        // flex-direction: column;
        // align-items: flex-start;
        gap: 16px;
        // align-self: stretch;
        border-radius: 8px;
        background: #fcfcfc;
        overflow: auto;
        height: 394px;
        .taskHistoryCard {
          width: 100%;
        }
      }
      .detailsContentAddComment {
        // margin-top: 139px;
        position: absolute;
        width: 100%;
        bottom: 5px;
        border-bottom: 1px solid #e4e4e7;
        border-radius: 6px;
        div {
          position: relative;
          .ticketCommentTextArea {
            margin-top: 6px;
            width: 100%;
            overflow: auto;
            height: 154px;
            resize: none;
            border-bottom: 45px solid white;
          }
          div {
            position: absolute;
            right: 4px;
            bottom: 4px;
            button {
              -webkit-user-select: none;
              -ms-user-select: none;
              user-select: none;
              display: flex;
              height: var(--size-size-button-sm, 36px);
              padding: var(--spacing-spacing-sm, 8px)
                var(--spacing-spacing-lg, 16px);
              justify-content: center;
              align-items: center;
              gap: var(--spacing-spacing-sm, 8px);
              border-radius: var(--radii-radius-button, 6px);
              background: var(--color-bg-secondary, #f4f4f5);

              /* Shadows/sm */
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
            button:hover {
              background-color: #e4e4e7 !important;
              transition: 0.3s;
            }
          }
        }
      }
      .shortMessagesParent {
        display: flex;
        gap: 4px;
        flex-wrap: nowrap;
        width: 100%;
        overflow: auto;
        scrollbar-width: thin;
      }
      .shortMessages:hover {
        transition: 0.3s;
        background-color: #e4e4e7;
      }
      .shortMessages {
        text-wrap: nowrap;box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        padding: 4px 6px;
        border: 1px solid #e4e4e7;
        border-radius: 6px;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        cursor: pointer;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        // margin-right: 4px;
      }
    }
  }
  .detailsContentEnd {
    display: flex;
    justify-content: space-between;
  }
  .detailsContentEndHR {
    height: 1px;
    background: #e7e7e7;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.availableWorkflows {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #e4e4e7;
  padding: 12px 16px 12px 16px;
  margin-bottom: 15px;
  cursor: pointer;
  span {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
  }
}

.availableWorkflows:hover {
  transition: 0.3s;
  background-color: #eff6ff;
}

.closeIcon {
  cursor: pointer;
}

.ticketsOverflow {
  height: 170px;
}

.contentWhose {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 50px;

  h5 {
    cursor: default;
    color: var(--color-icon-primary, #18181b);
    // margin-right: 40px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .prevNextStage {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      margin-right: unset;
    }
    h4 {
      color: var(--color-icon-muted, #71717a);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    div {
      display: flex;
      align-items: center;
      padding: 4px 8px 4px 5px;
      margin-right: 8px;
      margin-left: 8px;
      border-radius: 30px;
      background: var(--color-bg-success-muted, #f0fdf4);
      h3 {
        margin-left: 12px;
      }
    }
    h5 {
      border-radius: 20px;
      border: 1px dashed var(--color-border, #e4e4e7);
      background: var(--base-white, #fff);
      padding: 6px;
    }
  }

  .assignTicket {
    display: flex;
    align-items: center;
    // justify-content: center;
    // position: relative;
    h6 {
      padding: 0 !important;
      span {
        button {
          padding-top: 31px;
        }
      }
    }
    div:hover {
      background: var(--color-bg-secondary, #f4f4f5);
      transition: 0.3s;
      .miniClose {
        visibility: unset !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 12px;
      padding-top: 8px;
      padding-bottom: 8px;
      align-items: center;
      border-radius: var(--Radii-radius-sm, 4px);
      border-radius: 40px;
      display: flex;
      padding-right: 12px;
      align-items: center;
      gap: 12px;
      .miniClose {
        visibility: hidden;
        cursor: pointer;
      }
      h5 {
        cursor: default;
        color: var(--color-icon-primary, #18181b);

        /* text-sm/font-medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
    div {
      .islemamed {
        position: absolute;
        left: 130px;
        top: 30px;
      }
    }
    span {
      width: 1px;
      height: 10px;
      padding-bottom: 22px;
    }
    h4 {
      span {
        color: var(--color-icon-info, #2563eb);
        /* text-base/font-medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }

  span {
    color: var(--color-text, #09090b);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-right: 16px;
  }

  .whoseTextSender {
    position: relative;
    display: flex;
    width: 409px;
    padding: 10px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    border: 4px solid rgba(255, 255, 255, 0.5);
    background: #579cff;
    span {
      color: #fff;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  h5 {
    cursor: pointer;
  }
  .whoseTextSenderTT {
    display: flex;
    width: 401px;
    // height: 164px;
    padding: 16px;
    flex-direction: column;

    gap: 24px;
    position: absolute;
    left: 435px;
    bottom: 65px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 14px 3px rgba(0, 0, 0, 0.08);
    .whoseTextSenderHead {
      width: 369px;
      height: 32px;
      display: flex;
      padding-top: 4px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }
    .whoseTextSenderPerson {
      cursor: pointer;
      border-radius: 40px;
      background: #f5f5f5;
      display: flex;
      padding: 4px 0px;
      align-items: center;
      gap: 7px;
      align-self: stretch;
      img {
        width: 32px;
        height: 32px;
        margin-right: 7px;
      }
    }
  }
  .whoseTextLast {
    display: flex;
    align-items: center;
    img {
      margin-left: 24px;
      margin-right: 7px;
    }
    span {
      color: #84818a;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      margin-right: 24px;
    }
  }
}

.assigneeIMG {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.categorieSearch {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  input {
    width: 100%;
    height: 30px;
  }
  input::placeholder {
    color: #71717a;
  }
}

.smileToggleBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 8px;
  bottom: 12px;
  padding: 4px;
  border-radius: 20px;
  gap: 4px;
  background-color: #f4f4f5;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.smileToggleBtn:hover {
  transition: 0.3s;
  background-color: #e4e4e7;
}

.smiles {
  position: absolute;
  left: -121px;
  bottom: -50px !important;
  width: fit-content;
  box-shadow: 0px 2px 4px -2px #1018281a;
  box-shadow: 0px 4px 6px -1px #0000001a;
  height: 40px;
  border-radius: 6px;
  padding: 6px 12px;
  display: flex;
  gap: 10px;
  background-color: #fafafa;
  position: relative;
  .smileTriangle {
    width: 12px;
    height: 12px;
    background-color: #fafafa;

    rotate: 135deg;
    position: absolute;
    top: -4px;
    left: 137px;
  }
  .smileItemParent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: unset !important;
    gap: 10px;
    .smileItem {
      border-radius: 50px;
      border: 1px solid #e4e4e7;
      padding: 4px 6px 4px 6px;
      cursor: pointer;
    }
  }
}

.standartButton:hover {
  transition: 0.3s !important;
  background-color: #e4e4e7 !important;
}

@media screen and (max-width: 500px) {
  .detailsTitleL {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1280px) {
  .detailsContentR {
    width: 45% !important;
  }
  .detailsContentL {
    width: 55% !important;
    .detailsContentLhr {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 1000px) {
  .detailsContentR {
    width: 55% !important;
  }
  .detailsContentL {
    width: 45% !important;
    .contentKassaNum {
      hr {
        display: none;
      }
      flex-direction: column !important;
    }
  }
}

@media screen and (max-width: 815px) {
  .detailsContentR {
    width: 55% !important;
  }
  .detailsContentL {
    width: 45% !important;
    .contentKassaNum {
      hr {
        display: none;
      }
      flex-direction: column !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .smiles {
    left: 0px;
    bottom: -50px !important;

    .smileTriangle {
      top: -4px;
      left: 12px;
    }
  }
  .ticketDetails {
    height: 70vh !important;
    overflow: auto;
  }
  .detailsContentAddComment {
    .textAreaParent {
      display: flex !important;
      justify-content: center !important;
      .ticketCommentTextArea {
        width: 98% !important;
      }
    }
  }
  .detailsContent {
    flex-direction: column !important;

    .detailsContentR {
      width: 100% !important;
      height: 500px;
    }
    .detailsContentL {
      width: 100% !important;
      // display: none !important;
    }
    .detailsContentHR {
      height: 1px !important;
      width: 50% !important;
      display: none !important;
    }
  }
}

@media screen and (max-width: 730px) {
  .detailsTitle {
    flex-wrap: wrap;
    .detailsTitleL {
      flex-wrap: wrap;
    }
    .ticketTTT {
      margin-top: 12px;
    }
  }
}

@media screen and (max-width: 720px) {
  .contentWhose {
    height: unset !important;
    align-items: center;
  }
  .assignTicket {
    flex-direction: column !important;
    // align-items: unset !important;
  }
  .assigneeHR {
    display: none !important;
  }
}

@media screen and (max-width: 556px) {
  .detailsTitle {
    flex-wrap: wrap;
    .detailsTitleL {
      flex-direction: column !important;
      // flex-wrap: wrap;
      align-items: unset !important;
      span {
        padding: 0 !important;
      }
      div {
        padding: 0 !important;
      }
    }
    .ticketTTT {
      margin-top: 12px;
    }
  }
}

@media screen and (max-width: 470px) {
  .smiles {
    .smileItemParent {
      gap: 5px;
    }
  }
  .ticketDetails .detailsTitle .detailsTitleL div {
    flex-wrap: wrap;
  }
  .contentWhose .assignTicket h4 span {
    padding-left: 12px;
  }
  .contentWhose .assignTicket div {
    width: 200px !important;
  }
  .contentWhose {
    flex-direction: column !important;
    justify-content: unset !important;
    align-items: unset !important;
  }
  .assignTicket {
    justify-content: unset !important;
    align-items: unset !important;
  }
}
