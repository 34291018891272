.changepass {
  padding: 20px;
  border: 1px solid #e4e4e7;
  border-radius: 12px;
  span {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  hr{
    width: 100%;
    color: #E4E4E7;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
.notificationSwitch{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 24px;
    border: 1px solid #E4E4E7;
    border-radius: 12px;
    margin-bottom: 24px;
}