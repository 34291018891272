// ? Base

@import url(./base/base.scss);

// ? Component

@import url(./component/sideBar.scss);
@import url(./component/topBar.scss);
@import url(./component/tab.scss);
@import url(./component/ticket.scss);
@import url(./component/phoneNumberInput.scss);
@import url(./component/ticketDetails.scss);
@import url(./component/taskHistoryChanger.scss);
@import url(./component/faqCard.scss);
@import url(./component/alert.scss);
@import url(./component/announcmentCard.scss);
@import url(./component/dashboardTicketAreaTitle.scss);
@import url(./component/categorieCard.scss);
@import url(./component/paginition.scss);
@import url(./component/ticketLabelFromTicket.scss);
@import url(./component/comment.scss);
@import url(./component/dragAndDrop.scss);

// ? Pages

@import url(./pages/home.scss);
@import url(./pages/advancedReport.scss);
@import url(./pages/reports.scss);
@import url(./pages/FAQ.scss);
@import url(./pages/dashboard.scss);
@import url(./pages/login.scss);
@import url(./pages/settings.scss);
@import url(./pages/notFound.scss);
@import url(./pages/workflowPanel.scss);
@import url(./pages/clientSubmitForm.scss);

// ? Fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

.false {
  display: none !important;
}
.loadingText {
  margin-left: 0.75rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: rgb(244 63 94);
}

* {
  scrollbar-width: thin;
  scrollbar-color: #e4e4e7 #ffffff;
}

*::-webkit-scrollbar {
  width: -123px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #e4e4e7;
  border-radius: 22px;
  border: -3px solid #ffffff;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}


// div[ref="printRef"] {
//   visibility: visible;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 150mm;
// }
