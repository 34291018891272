.panelParent {
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: #264b5d;
  //   align-items: center;
  .panelContent {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    h2 {
      color: white;
      font-size: 24px;
      margin-bottom: 20px;
    }
    .selectGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: white;
      }
      button {
        width: 400px;
        span {
          color: black;
        }
      }
    }
    .inputGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: white;
      }
      input {
        width: 400px;
      }
    }
  }
}
