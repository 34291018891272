.topBar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 14px 36px;
  gap: 16px;
  // border-bottom: 1px solid #e4e4e7;
  .moonIcon {
    position: absolute;
    left: 300px;
    cursor: pointer;
  }
  .languageChanger {
    display: flex;
    padding: 8px 18px;
    align-items: center;
    gap: 12px;
    border-radius: 1000px;
    margin-right: 16px;
    position: relative;
    cursor: pointer;
    p {
      position: absolute;
      top: 50px;
      padding-right: 18px;
      color: var(--color-text, #09090b);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      span {
        padding: 8px 12px;
        border-radius: 14px;
        background: var(--color-bg-elevated, #f4f4f5);
      }
    }
    span {
      color: var(--color-text, #09090b);
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    div {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
  .notification {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .chime {
      display: flex;
      align-items: center;
    }
  }
  .disabledNotification {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .userProfile {
    display: flex;
    flex-direction: column;
    height: 56px;
    align-items: center;
    .userProfileTT:hover {
      transition: 0.3s;
      background-color: #e4e4e7;
    }
    .userProfileTTActive:hover {
      background-color: #f4f4f5;
      transition: 0.3s;
    }

    .userProfileTTActive {
      display: flex;
      height: 56px;
      min-width: 174px;
      cursor: pointer;
      padding: 12px 18px 12px 5px;
      align-items: center;
      gap: 12px;
      // margin-left: 17px;
      background: #ffffff;
      /* Shadows/sm-strong */
      box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1),
        0px 1px 3px rgba(16, 24, 40, 0.1);
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      img {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
      span {
        color: var(--color-text, #09090b);
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .userProfileTT {
      display: flex;
      height: 56px;
      min-width: 174px;
      cursor: pointer;
      padding: 12px 18px 12px 5px;
      align-items: center;
      gap: 12px;
      // margin-left: 17px;
      background: #ffffff;
      /* Shadows/sm-strong */
      // box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1),
      //   0px 1px 3px rgba(16, 24, 40, 0.1);
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      img {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
      span {
        color: var(--color-text, #09090b);
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .profileDrop {
      background: #ffffff;
      box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1),
        0px 1px 3px rgba(16, 24, 40, 0.1);
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      padding: 8px;
      div {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        width: 90% !important;
        border-radius: 14px;
        display: flex;
        align-items: center;
        background: var(--color-bg-elevated, #f4f4f5);
        padding: 12px 18px;
        h6 {
          color: var(--color-text, #09090b);
          -webkit-user-select: none; /* Safari */
          -ms-user-select: none; /* IE 10 and IE 11 */
          user-select: none; /* Standard syntax */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-right: 12px;
        }
      }
      div:hover {
        background: #e4e4e7;
        transition: 0.3s;
      }
    }
  }
}

.logoImg {
  width: 123px;
  // height: 70px;
  position: absolute;
  cursor: pointer;
  top: 21px;
  left: 32px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.notificationArea {
  height: 609px;
  width: 377px;

  overflow: auto;
  .notificationCard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    // gap: 12px;
    width: 360px;
    height: auto;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    .notifiDate {
      gap: 3px;
      height: fit-content;
      span {
        width: 64px !important;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #71717a;
      }
    }
    div {
      .notifiTitle {
        width: 82px;
        height: 20px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #09090b;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
      .notifiDesc {
        width: 252px;
        height: fit-content;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #71717a;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }
    }
  }
  .notificationCardUnread {
    display: flex;
    width: 360px;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    // gap: 12px;
    height: auto;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    background-color: #dbeafe;
    cursor: pointer;
    .notifiDate {
      gap: 3px;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #71717a;
      }
    }
    div {
      .notifiTitle {
        padding-left: 8px;
        width: 82px;
        height: 20px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #09090b;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
      .notifiDesc {
        width: 252px;
        padding-left: 8px;
        height: fit-content;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #71717a;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }
    }
  }
  .notificationCard:hover {
    background-color: #fafafa;
    transition: 0.3s;
  }
}

.changePassDialog {
  max-width: 425px;
  margin-top: 6%;
  margin-left: 24%;
}

.notificationTitle {
  // margin-bottom: 16px;
  height: 36px;
  display: flex;
  margin-left: 10px;
  align-items: center;
  gap: 30px;
  .nonactive {
    color: var(--color-icon-muted, #71717a);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }
  .active {
    cursor: pointer;
    color: var(--color-text, #09090b);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.BellWithDotI {
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f5;
  border-radius: 50%;
}
.BellWithDotI:hover {
  background-color: #e4e4e7;
  transition: 0.3s;
}

.BellWithOutDotI {
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.BellWithOutDotI:hover {
  transition: 0.5s;
  background-color: #f4f4f5;
}
.topBarResponsiveIMG {
  display: none;
}

@media screen and (max-width: 760px) {
  .topBar {
    width: 90%;
    margin-left: 10%;
  }
  .topBarResponsiveIMG {
    display: unset;
    width: 83.34px;
    height: 48px;
    object-fit: contain;
    position: absolute;
    left: 30px;
  }
  .logoImg {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .userProfileTT {
    padding-right: 0 !important;
  }
  .topBarUsername {
    display: none;
  }
  .userProfileTT {
    min-width: 95px !important;
    img {
      object-fit: contain;
    }
  }
  .userProfileTTActive {
    min-width: 95px !important;
  }
  .profileDrop {
    div {
      // width: 0 !important;
      h6 {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .topBar {
    padding-right: 20px !important;
  }
}
