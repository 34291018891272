.ticketLabelParent {
  display: flex;
  //   align-items: center;
  .ticketLabel {
    position: relative;
    width: 44px;
    // height: ;
    border-radius: 8px;
    margin-right: 6px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ticketLabelCircle {
      position: absolute;
      top: 12px;
    }
    div {
      width: 100% !important;
      height: 100% !important;
      position: relative;
      p {
        max-height: 42px;
        position: absolute;
        bottom: 40%;
        right: -131%;
        transform: rotate(-0.25turn);
        width: 150px !important;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .ticketLabelParent {
    display: block;
    .ticketLabel {
      width: 100% !important;
      height: 44px;
      margin-bottom: 2px !important;
      .ticketLabelCircle {
        right: 16px;
        top: 16px;
      }
      div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          width: fit-content !important;
          transform: unset;
          position: unset;
          white-space: nowrap;
        }
      }
    }
  }
}
