.clientSubmitForm {
  height: 89vh !important;
  overflow: auto;
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;
}

.firstPopUpForClient {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  h2 {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  p {
    span {
      color: black;
    }
    color: var(--color-icon-muted, #71717a);
    font-family: Inter;
    font-size: 14px;
    font-style: light;
    font-weight: 300;
    line-height: 20px;
  }
  button {
    width: 150px;
  }
}

.dialogContentFirstPopUp {
  position: relative;
  margin-top: 150px;
  width: 500px !important;
  margin-left: 350px;
  .firstPopUpCloseBtn {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.clientSubmitFormTitle {
  width: 100%;
  padding: 20px;
  display: flex;
  background-color: #0f172a;
  color: #fff;
  .clientSubmitFormTitleL {
    width: 45%;
  }
  .clientSubmitFormTitleR {
    width: 55%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 100px;
    h2 {
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }
    span {
      font-family: Inter;
      font-size: 14px;
      font-style: light;
      font-weight: 300;
      line-height: 20px;
    }
  }
}
.clientSubmitFormBtn {
  width: 200px;
  margin-top: 30px;
}
