.reportsTT {
  padding: 24px 24px 37px 24px;
  background: #fafafa;
  margin-left: 288px;
  border-top: 2px solid #e4e4e7;
  border-left: 2px solid #e4e4e7;
  .reports {
    display: flex;
    width: 100%;
    height: auto;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #fff;
    .reportsTitle {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      h2 {
        color: #2e2c34;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .reportsTitleTT {
        display: flex;
        align-items: center;
        gap: 20px;
        h2 {
          color: #2e2c34;
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .reportsTitleR {
        display: flex;
        align-items: center;
        a {
          button {
            width: 105px;
          }
        }
      }
    }

    .advancedReportParent {
      width: 100%;
      overflow: auto;
    }

    #advancedReport {
      margin-bottom: 24px;
      color: #000;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    #advancedReport tr {
      width: fit-content;
      border-bottom: #e4e4e7 1px solid;
      // text-align: center;
    }

    #advancedReport td,
    #advancedReport th {
      width: fit-content;
      white-space: nowrap;
      padding: 12px 16px;
      font-family: Inter;
      line-height: 16px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
    }
    #advancedReport hr {
      width: fit-content;
      border-bottom: 1px solid #e7e7e7;
    }

    #advancedReport th {
      text-wrap: nowrap;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      color: #000;
      width: fit-content;
      border-collapse: unset;
    }
    .newTicketTextDate {
      margin-right: 20px;
    }
    .prevNext {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }
  }
}

.filter {
  display: flex;
  padding: 32px;
  width: 100%;
  border-top: 4px solid #fafafa;
  border-bottom: 4px solid #fafafa;
  gap: 32px;
  .filterSelect {
    width: 190px !important;
    // input {
    //   height: 25px !important;
    //   overflow: auto;
    // }
    // select {
    //   height: 25px !important;
    //   overflow: auto;
    // }
  }
}

.reportsFilterBtn{
  margin-right: 24px;
  background-color: #F4F4F5 !important;
}
