.tickets {
  margin-left: 288px;
  background-color: #fafafa;
  padding: 24px 3px 8px 24px;
  display: flex;
  border-top: 2px solid #e4e4e7;
  border-left: 2px solid #e4e4e7;
  .ticketsContent {
    background-color: #fff;
    padding: 24px;
    border-radius: 12px;
    background: #fff;
    width: 100%;
    .ticketsTitleTT {
      display: flex;
      justify-content: space-between;
      .ticketsTitle {
        display: flex;
        width: 100%;
        margin-bottom: 24px;
        // align-items: center;
        justify-content: space-between;
        .ticketTitleTabs {
          width: 80%;
        }
      }
    }
    .ticketFilter {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }
    .ticketsArea {
      height: 689px;
      overflow: auto;
    }
  }
}
.pinIcon {
  width: 24px;
  height: 24px;
  border-radius: 21px;
  border: 1px solid #20bedb;
  background: #5697b7;
}

.ticketsPaginition {
  display: flex;
  gap: 12px;
}

.searchInp {
  display: flex;
}

.searchBtn {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 10px 12px;
  background-color: #f4f4f5;
  cursor: pointer;
}
.searchBtnMyTeam {
  margin-left: 10px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 10px 12px;
  background-color: #f4f4f5;
  cursor: pointer;
}

.home {
  position: relative;
  .newTicket {
    width: 406px;
    height: 916px;
    padding: 20px 20px 72px 20px;
    margin-left: 24px;
    border-radius: 4px;
    background: #fff;
    .newTicketTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .newTicketR {
        display: flex;
        align-items: center;
        span {
          margin-left: 12px;
          color: var(--color-text, #09090b);
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
        }
      }
    }
    .newTicketDate {
      margin-bottom: 15px;
      display: flex;
      margin-top: 4px;
      width: 300px;
      height: 28px;
      align-items: center;
      span {
        color: var(--color-icon-muted, #71717a);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
      }
    }
    .newTicketHR {
      width: 368px;
      height: 1px;
      background: var(--color-border, #e4e4e7);
      margin-bottom: 25px;
    }
    .newTicketInpBox {
      margin-bottom: 16px;
      input {
        display: flex;
        width: 366px;
        height: 50px;
        padding: 8px 12px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #e7e7e7;
        outline: #e7e7e7;
      }
      input::placeholder {
        color: #757575;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }
    .newTicketSelect {
      margin-top: 16px;
      margin-bottom: 16px;
      select {
        border-radius: 4px;
        border: 1px solid #e7e7e7;
        display: flex;
        width: 366px;
        height: 50px;
        padding: 8px 12px;
        align-items: center;
        outline: #e7e7e7;
        gap: 63px;
      }
    }
    .newTicketComment {
      input {
        display: flex;
        width: 366px;
        height: 91px;
        padding: 16px 20px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #e7e7e7;
        outline: #e7e7e7;
      }
    }
    .newTicketSendBtn {
      margin-top: 42px;
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.32px;
      display: flex;
      width: 366px;
      height: 60px;
      padding: 13px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      border-radius: 4px;
      border: none;
      background: #5697b7;
    }
  }
}

.closeIcon {
  cursor: pointer;
}

.newTicketText {
  color: var(--color-text-onColor, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-left: 8px;
}

.ticketTitleTabsMy {
  display: flex;
  gap: 70px;
}

@media screen and (max-width: 1120px) {
  .ticketsTitle {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 953px) {
  .searchInp {
    width: 100%;
  }
  .ticketTitleTabsMy {
    flex-direction: column-reverse;
    width: 100%;
    gap: 24px;
  }
  .ticketFilter {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 760px) {
  .tickets {
    margin-left: unset !important;
    padding: 24px !important;
  }
}

@media screen and (max-width: 650px) {
  .ticketsTitle {
    flex-wrap: wrap;
    width: 100% !important;
    button {
      width: 100%;
    }
    .ticketTitleTabs {
      width: 100% !important;

      .ticketFilter {
        width: 100% !important;
        .searchInp {
          width: 100% !important;
        }
        button {
          width: 100% !important;
        }
      }
    }
  }
  .ticketFilter {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 550px) {
  .tabListClass {
    width: 100%;
    height: 51px;
    overflow: auto;
  }
  .ticketTitleTabsMy {
    gap: 12px;
  }
}
